import {Routes} from '@angular/router';
import {LayoutComponent} from "./pages/layout/layout.component";
import {AutoLoginPartialRoutesGuard} from "angular-auth-oidc-client";
import {CallbackComponent} from "./auth/callback/callback.component";
import {GdprConsentFormComponent} from "./pages/public/gdpr-consent-form/gdpr-consent-form.component";
import {LearnMoreComponent} from "./pages/learn-more/learn-more.component";
import {adminGuard} from "./shared/guards/admin.guard";

export const routes: Routes = [
  {
    path: 'gdpr-consent-form',
    component: GdprConsentFormComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: '',
        redirectTo: '/contacts/all',
        pathMatch: "full"
      },
      {
        path: 'contacts',
        children: [
          {
            path: 'all',
            loadComponent: () => import('./pages/contacts/contacts-list/contacts-list.component')
              .then(c => c.ContactsListComponent)
          },
          {
            path: 'lists',
            loadComponent: () => import('./pages/contacts/my-contacts-list/my-contacts-list.component')
              .then(c => c.MyContactsListComponent)
          },
          {
            path: 'review',
            loadComponent: () => import('./pages/contacts/review-contacts/review-contacts.component')
              .then(c => c.ReviewContactsComponent)
          }
        ]
      },
      {
        path: 'meetings',
        loadComponent: () => import('./pages/meetings/meetings.component')
          .then(c => c.MeetingsComponent),
      },
      {
        path: 'companies',
        loadComponent: () => import('./pages/companies/companies.component')
          .then(c => c.CompaniesComponent),
      },
      {
        path: 'groups',
        loadComponent: () => import('./pages/groups/groups.component')
          .then(c => c.GroupsComponent),
      },
      {
        path: 'administration',
        loadComponent: () =>
          import('./pages/administration/administration.component').then(
            (c) => c.AdministrationComponent
          ),
        canActivate: [adminGuard],
      },
      {
        path: 'learn-more',
        component: LearnMoreComponent,
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/learn-more/pages/learn-more-main/learn-more-main.component')
              .then(c => c.LearnMoreMainComponent)
          },
          {
            path: 'getting-started',
            loadComponent: () => import('./pages/learn-more/pages/getting-started-docs/getting-started-docs.component')
              .then(c => c.GettingStartedDocsComponent)
          },
          {
            path: 'contacts-management',
            loadComponent: () =>
              import('./pages/learn-more/pages/contacts-management-docs/contacts-management-docs.component')
                .then(c => c.ContactsManagementDocsComponent)
          },
          {
            path: 'contact-lists',
            loadComponent: () => import('./pages/learn-more/pages/contact-lists-docs/contact-lists-docs.component')
              .then(c => c.ContactListsDocsComponent)
          },
          {
            path: 'gdpr',
            loadComponent: () => import('./pages/learn-more/pages/gdpr-docs/gdpr-docs.component')
              .then(c => c.GdprDocsComponent)
          },
          {
            path: 'meetings-and-events',
            loadComponent: () => import('./pages/learn-more/pages/meetings-events-docs/meetings-events-docs.component')
              .then(c => c.MeetingsEventsDocsComponent)
          },
          {
            path: 'companies-and-groups',
            loadComponent: () =>
              import('./pages/learn-more/pages/companies-groups-docs/companies-groups-docs.component')
                .then(c => c.CompaniesGroupsDocsComponent)
          },
          {
            path: 'faq',
            loadComponent: () =>
              import('./pages/learn-more/pages/faq-docs/faq-docs.component')
                .then(c => c.FaqDocsComponent)
          },
          {
            path: '**',
            redirectTo: '/learn-more',
          }
        ]
      }
    ]
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: '**',
    redirectTo: '/contacts/all',
  }
];
