import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom} from '@angular/core';
import {provideRouter, Router} from '@angular/router';
import {routes} from './app.routes';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {InlineSVGModule} from "ng-inline-svg-2";
import {AbstractSecurityStorage, DefaultLocalStorageService, provideAuth} from 'angular-auth-oidc-client';
import {authConfig} from './auth/auth.config';
import {provideToastr} from "ngx-toastr";
import {provideMatomo, withRouter} from "ngx-matomo-client";
import {environment} from "../environments/environment";
import {createErrorHandler, TraceService} from "@sentry/angular";
import {AuthInterceptor} from "./auth/auth.interceptor";
import {NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule} from "ngx-zendesk-webwidget";

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'crm-atoz-solutions.zendesk.com';
  callback(zE:any) {
    zE("messenger", "hide");
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideAnimationsAsync(),
    provideToastr({
      positionClass: 'toast-bottom-left',
      disableTimeOut: 'extendedTimeOut'
    }),
    importProvidersFrom(InlineSVGModule.forRoot()),
    provideAuth(authConfig),
    {provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService},
    provideMatomo({
      disabled: environment.name === "dev",
      siteId: environment.matomoSiteId,
      trackerUrl: "https://bckl.matomo.cloud/",
    }, withRouter()),
    /*BEGIN Sentry config*/
    importProvidersFrom(
      NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
    ),
    {
      provide: ErrorHandler, useValue: createErrorHandler()},
    {
      provide: TraceService, deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    }
    /*END Sentry config*/
  ]
};
