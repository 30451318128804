import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgOptimizedImage, TitleCasePipe} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {InlineSVGModule} from "ng-inline-svg-2";
import {TooltipModule} from "primeng/tooltip";
import {AuthService} from "../../../../auth/auth.service";
import {Observable} from "rxjs";
import {User} from "../../../../shared/models/user.model";
import {AvatarUser} from "../../../../shared/components/avatar-component/models/avatar-user.model";
import {
  AvatarSingularComponent
} from "../../../../shared/components/avatar-component/avatar-singular/avatar-singular.component";
import {UtilsService} from "../../../../shared/services/global/utils-service.service";
import {UserRole} from "../../../../shared/enums/user-role.enum";
import {NgxZendeskWebwidgetService} from "ngx-zendesk-webwidget";

@Component({
  selector: 'crm-sidebar',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    AsyncPipe,
    InlineSVGModule,
    TooltipModule,
    AvatarSingularComponent,
    TitleCasePipe
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  currentUser$: Observable<User>;
  isZendeskOpen = false;
  protected readonly UserRole = UserRole;

  constructor(private readonly oidcSecurityService: OidcSecurityService,
              private readonly authService: AuthService,
              public readonly utilsService: UtilsService,
              public readonly _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService) {
    this.currentUser$ = authService.currentUser;
  }

  // This function is used to toggle a sublist of links in the sidebar.
  toggleSublist(sublist: HTMLElement, arrowIcon: HTMLElement) {
    sublist.classList.toggle('hideSubList');
    arrowIcon.classList.toggle('-rotate-180');
  }

  getCurrentUserAvatar() {
    const currentUser = this.authService.currentUser.value;
    return new AvatarUser(currentUser.person);
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      location.reload();
    });
  }

  onSupportButtonClick() {
    if (this.isZendeskOpen) {
      this._NgxZendeskWebwidgetService.zE('messenger', 'hide');
      this.isZendeskOpen = false;
      return;
    } else {
      this._NgxZendeskWebwidgetService.zE('messenger', 'show');
      this._NgxZendeskWebwidgetService.zE('messenger', 'open');
      this.isZendeskOpen = true;
    }

    this._NgxZendeskWebwidgetService.zE('messenger:on', 'close', () => {
      this._NgxZendeskWebwidgetService.zE('messenger','hide');
      this.isZendeskOpen = false;
    });
  }
}
